import React from 'react';
import { useNavigate } from 'react-router-dom';
import './_header.scss';

const TextBackCTA: React.FC = () => {
  const navigate = useNavigate();

  return (
    <span 
      className="text-back-cta" 
      onClick={() => navigate('/dashboard')}
    >
      Back to Dashboard
    </span>
  );
};

export default TextBackCTA; 